import React, { useEffect, useRef, useState } from 'react';
import './header.css';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/Logo.svg';
import hamburger from '../../../assets/images/hamburger.svg';
import SideMenu from '../sideMenu/sideMenu';

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, origin } = location;
  const [menuShow, setMenuShow] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
    setPageTitle();
  }, []);

  function setPageTitle() {
    switch (pathname) {
      case '/':
        document.title = 'FinGate.io';
        break;
      case '/products':
        document.title = 'FinGate.io - Ürünler';
        break;
      case '/reference':
        document.title = 'FinGate.io - Referanslar';
        break;
      case '/about':
        document.title = 'FinGate.io - Hakkımızda';
        break;
      case '/contact':
        document.title = 'FinGate.io - İletişim';
        break;
      default:
        document.title = 'FinGate.io';
    }
  }
  function handleNavigate(path) {
    navigate(path);
  }

  return (
    <div className="header-main">
      <div className="header-cont">
        <div onClick={() => handleNavigate('/')} className="c-pointer">
          <img alt="logo" src={logo} className="header-logo" />
        </div>
        <div className="header-pages">
          <div className={`m-r-24 c-pointer ${pathname === '/products' && ' selected'}`} onClick={() => handleNavigate('/products')}>
            ÜRÜNLER
          </div>
          <div className={`m-r-24 c-pointer ${pathname === '/reference' && ' selected'}`} onClick={() => handleNavigate('/reference')}>
            REFERANSLAR
          </div>
          <div className={`m-r-24 c-pointer ${pathname === '/about' && ' selected'}`} onClick={() => handleNavigate('/about')}>
            HAKKIMIZDA
          </div>
          <div className={`c-pointer ${pathname === '/contact' && ' selected'}`} onClick={() => handleNavigate('/contact')}>
            İLETİŞİM
          </div>
        </div>
        <div className="hamburger-button" onClick={() => setMenuShow(!menuShow)}>
          <img alt="sideMenu" src={hamburger} />
        </div>
        <SideMenu show={menuShow} toggle={(e) => setMenuShow(e)}>
          <div className="side-cont">
            <div className={`side-button ${pathname === '/' && ' selected'}`} onClick={() => handleNavigate('/')}>
              ANASAYFA
            </div>
            <div className={`side-button ${pathname === '/products' && ' selected'}`} onClick={() => handleNavigate('/products')}>
              ÜRÜNLER
            </div>
            <div className={`side-button ${pathname === '/reference' && ' selected'}`} onClick={() => handleNavigate('/reference')}>
              REFERANSLAR
            </div>
            <div className={`side-button ${pathname === '/about' && ' selected'}`} onClick={() => handleNavigate('/about')}>
              HAKKIMIZDA
            </div>
            <div className={`side-button ${pathname === '/contact' && ' selected'}`} onClick={() => handleNavigate('/contact')}>
              İLETİŞİM
            </div>
          </div>
        </SideMenu>
      </div>
    </div>
  );
}

export default Header;
