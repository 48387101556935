import React from 'react';
import Header from '../../views/header/header';
import Footer from '../../views/footer/footer';
import './products.css';
import p1 from '../../../assets/images/p1.svg';
import p2 from '../../../assets/images/p2.svg';
import p3 from '../../../assets/images/p3.svg';
import p4 from '../../../assets/images/p4.svg';
import sc from '../../../assets/images/scLogo.svg';
import qr from '../../../assets/images/paraplus-qr.svg';
import paraLogo from '../../../assets/images/paraLogo.svg';
import arrow from '../../../assets/images/arrow-1.svg';
import Slider from '../../views/slider/slider';
import st5 from '../../../assets/images/topSlider/s1.svg';
import st4 from '../../../assets/images/topSlider/s2.svg';
import st3 from '../../../assets/images/topSlider/s3.svg';
import st2 from '../../../assets/images/topSlider/s4.svg';
import st1 from '../../../assets/images/topSlider/s5.svg';
import sm1 from '../../../assets/images/midSlider/1.svg';
import sm2 from '../../../assets/images/midSlider/2.svg';
import sm3 from '../../../assets/images/midSlider/3.svg';
import sm4 from '../../../assets/images/midSlider/4.svg';

function Products() {
  const topSliderData = [
    {
      image: st1,
      text: 'ParaPlus ile farklı bankalardaki tüm kredi kartlarını tek ekrandan kolayca takip et ve yönet!',
    }, {
      image: st2,
      text: 'Kredi kartına ait tüm işlemleri görüntüle!',
    }, {
      image: st3,
      text: 'Ayrıntılı harcama analizleri takip et!',
    }, {
      image: st4,
      text: 'Tüm bankaların güncel kredi kartı kampanyalarına erişim sağla!',
    }, {
      image: st5,
      text: 'ParaPlus’a özel “Refinance” tekliflerini değerlendir!',
    },
  ];
  const midSliderData = [
    {
      image: sm1,
      text: 'Müşterilere ihtiyaç duydukları anda sürtünmesiz deneyimle bankalara ait alışveriş kredisi çözümünü sunmak.',
    }, {
      image: sm2,
      text: 'Müşterilere ihtiyaç duydukları anda sürtünmesiz deneyimle bankalara ait alışveriş kredisi çözümünü sunmak.',
    }, {
      image: sm3,
      text: 'Müşterilere ihtiyaç duydukları anda sürtünmesiz deneyimle bankalara ait alışveriş kredisi çözümünü sunmak.',
    }, {
      image: sm4,
      text: 'Müşterilere ihtiyaç duydukları anda sürtünmesiz deneyimle bankalara ait alışveriş kredisi çözümünü sunmak.',
    },
  ];
  return (
    <div className="mainPage">
      <div className="container-2">
        <div className="products-cont-1">
          Tüm kredi kartların tek uygulamada!
        </div>
        <div className="products-cont-2">
          <div className="d-flex f-column align-center d-none-small">
            <img alt="paraplus" src={paraLogo} />
            <img alt="paraplus" src={qr} className="m-t-24" />
            <div className="d-flex align-center m-t-24">
              <div onClick={() => window.open('https://paraplus.app/')} className="products-t1">Detaylı Bilgi</div>
              <img alt="arrow" src={arrow} className="m-l-8" />
            </div>
          </div>
          <div className="d-flex-small align-center justify-space-around w100 m-t-16">
            <img alt="paraplus" src={paraLogo} className="para-logo" />
            <div className="d-flex align-center">
              <div onClick={() => window.open('https://paraplus.page.link/download_paraplus')} className="products-t1">Hemen İndir</div>
              <img alt="arrow" src={arrow} className="m-l-8" />
            </div>
          </div>
          <Slider data={topSliderData} className="top-slider" />
        </div>
        <div className="products-cont-10">
          Anında alışveriş kredisi!
        </div>
        <div className="products-cont-2">
          <div className="d-flex f-column align-center">
            <img alt="sc" src={sc} className="sc-logo" />
          </div>
          <Slider data={midSliderData} className="mid-slider" />
        </div>
        <div className="products-cont-3">
          <div className="products-t2">
            Anında alışveriş kredisi’ni kullanmaya hızlıca başlayın!
          </div>
          <div className="d-flex max-1040 m-t-64 d-none-small">
            <div className="m-r-16 f1 d-flex f-column align-center">
              <img alt="number" src={p1} />
              <div className="products-t3">FinGate ile İletişime Geç!</div>
              <div className="products-t4">FinGate.io sayfamızdaki İletişim sayfası üzerinden info@fingate.io mail atarak Anında Alışveriş Kredisi başvuru sürecini başlatabilirsin.</div>
            </div>
            <div className="m-l-16 f1 d-flex f-column align-center">
              <img alt="number" src={p2} />
              <div className="products-t3">Sözleşmeyi İncele ve İmzala</div>
              <div className="products-t4">FinGate satış ekibi tarafınızla iletişime geçerek üye işyeri sözleşme gönderimi sağlayacaktır.</div>
            </div>
          </div>
          <div className="d-flex max-1040 m-t-32 d-none-small">
            <div className="m-r-16 f1 d-flex f-column align-center">
              <img alt="number" src={p3} />
              <div className="products-t3">{`${"Bankalarda Hesap'larını Aç!"}`}</div>
              <div className="products-t4">Alışveriş Kredisi servisleri özelinde anlaşmalı olduğumuz bankalarda hesaplarını açıp satış ekibimizle paylaşın.</div>
            </div>
            <div className="m-l-16 f1 d-flex f-column align-center">
              <img alt="number" src={p4} />
              <div className="products-t3">Entegrasyonu Tamamla ve Kullanmaya Başla!</div>
              <div className="products-t4">Anında Alışveriş Kredisi servisini ödeme sayfana entegre ederek müşterilerinden alışveriş kredisi ile ödemelerini alabilirsin</div>
            </div>
          </div>
          <div className="d-flex-small f-column">
            <div className="d-flex f-column align-center m-t-32 m-r-32 m-l-32">
              <img alt="number" src={p1} />
              <div className="products-t3">FinGate ile İletişime Geç!</div>
              <div className="products-t4">FinGate.io sayfamızdaki İletişim sayfası üzerinden info@fingate.io mail atarak Anında Alışveriş Kredisi başvuru sürecini başlatabilirsin.</div>
            </div>
            <div className="d-flex f-column align-center m-t-32 m-r-32 m-l-32">
              <img alt="number" src={p2} />
              <div className="products-t3">Sözleşmeyi İncele ve İmzala</div>
              <div className="products-t4">FinGate satış ekibi tarafınızla iletişime geçerek üye işyeri sözleşme gönderimi sağlayacaktır.</div>
            </div>
            <div className="d-flex f-column align-center m-t-32 m-r-32 m-l-32">
              <img alt="number" src={p3} />
              <div className="products-t3">{`${"Bankalarda Hesap'larını Aç!"}`}</div>
              <div className="products-t4">Alışveriş Kredisi servisleri özelinde anlaşmalı olduğumuz bankalarda hesaplarını açıp satış ekibimizle paylaşın.</div>
            </div>
            <div className="d-flex f-column align-center m-t-32 m-r-32 m-l-32">
              <img alt="number" src={p4} />
              <div className="products-t3">Entegrasyonu Tamamla ve Kullanmaya Başla!</div>
              <div className="products-t4">Anında Alışveriş Kredisi servisini ödeme sayfana entegre ederek müşterilerinden alışveriş kredisi ile ödemelerini alabilirsin</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Header />
    </div>
  );
}

export default Products;
