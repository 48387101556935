import React, { useEffect, useState } from 'react';
import './slider.css';

function Slider({ data, className }) {
  const [selectedData, setSelectedData] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    if (data?.length > 0) {
      setSelectedData(data[0]);
      setSelectedIndex(0);
    }
  }, [data]);
  useEffect(() => {
    let count = 0;
    const interval = setInterval(() => {
      if (count === data.length - 1) {
        count = 0;
      } else {
        count += 1;
      }
      setSelectedData(data[count]);
      setSelectedIndex(count);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  if (!selectedData) {
    return <div />;
  }
  return (
    <div className={`slider-cont ${className}`}>
      <div className="d-flex align-center">
        <div className="d-flex f-column align-center">
          <img alt="img" src={selectedData.image} className="slider-item" />
          <div className="slider-text d-flex-small">{selectedData.text}</div>
          <div className="d-flex align-center m-t-24">
            {data.map((item, index) => (
              <div key={index} className={`slider-dot ${selectedIndex === index && 'selected'}`} />
            ))}
          </div>
        </div>
        <div className="slider-text d-none-small">{selectedData.text}</div>
      </div>
    </div>
  );
}

export default Slider;
