import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route, Navigate,
} from 'react-router-dom';
import './App.css';
import './assets/fonts/fonts.css';
import Home from './components/pages/home/home';
import About from './components/pages/about/about';
import Products from './components/pages/products/products';
import Reference from './components/pages/reference/reference';
import Contact from './components/pages/contact/contact';
import StatusCheck from './components/pages/statusCheck/statusCheck';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" Component={Home} />
        <Route path="/statuscheck" Component={StatusCheck} />
        <Route path="/products" Component={Products} />
        <Route path="/reference" Component={Reference} />
        <Route path="/about" Component={About} />
        <Route path="/contact" Component={Contact} />
        <Route
          path="*"
          element={(
            <Navigate
              replace
              to="/"
            />
              )}
        />
      </Routes>
    </Router>
  );
}

export default App;
