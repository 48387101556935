import React from 'react';
import './footer.css';
import logo from '../../../assets/images/Logo.svg';
import iso from '../../../assets/images/footerIso.svg';

function Footer() {
  return (
    <div className="footer-main">
      <div className="footer-row m-t-32">
        <div className="footer-cont">
          <div className="footer-cont0">
            <img alt="logo" src={logo} className="footer-logo" />
            <div className="footer-line" />
            <div className="footer-cont1">
              Copyright 2023 Maslak Teknoloji A.Ş.
            </div>
          </div>
        </div>
        <div className="footer-cont2">
          <div className="footer-kvkk" onClick={() => window.open('/KVKK_Aydinlatma_Metni_Fingate.pdf')}>
            KVKK Aydınlatma  Metni
          </div>
          <div className="footer-kvkk m-t-16" onClick={() => window.open('/Acik_Riza_Metni_Fingate.pdf')}>
            Açık Rıza  Metni
          </div>
          <div className="footer-kvkk m-t-16" onClick={() => window.open('/KVKK_Basvuru_Formu_Fingate.pdf')}>
            KVKK Başvuru Formu
          </div>
        </div>
      </div>
      <div className="footer-row m-t-20">
        <img alt="iso" src={iso} />
      </div>
    </div>
  );
}

export default Footer;
