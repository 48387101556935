import React from 'react';
import Header from '../../views/header/header';
import Footer from '../../views/footer/footer';
import './home.css';
import img1 from '../../../assets/images/homeImg1.png';
import img2 from '../../../assets/images/homeImg2.png';
import ic1 from '../../../assets/images/homeIcon1.svg';
import ic2 from '../../../assets/images/homeIcon2.svg';
import ic3 from '../../../assets/images/homeIcon3.svg';
import ic4 from '../../../assets/images/homeIcon4.svg';
import ic5 from '../../../assets/images/homeIcon5.svg';
import ic6 from '../../../assets/images/homeIcon6.svg';
import picture from '../../../assets/images/homeImg3.png';

function Home() {
  return (
    <div className="mainPage">
      <Header />
      <div className="container">
        <div className="home-cont">
          <div className="home-cont001">
            <div className="home-t1">Tüm Finansal Servisler için Super HUB</div>
            <div className="home-t2 m-t-32">Servis Bankacalığı (BaaS) ve Açık Bankacılık servislerini kullanarak, bankaların sundukları ürün ve servislere kolay, hızlı ve yalın bir halde, tek merkezden erişilmesine imkan sağlıyoruz.</div>
            <div className="home-t2 m-t-16">İster e-ticaret platformunuzda birden çok bankanın alışveriş kredi servislerini kullanın, ister bireysel veya kurumsal banka hesaplarınızı tek bir ekrandan veya ERP sisteminizden görüntülemek isteyin, FinGate sizin için burada.</div>
          </div>
          <img alt="img1" src={img1} className="home-img-1" />
        </div>
        <div className="home-cont00">
          <div className="home-cont001">
            <img alt="img2" src={img2} className="home-img-2" />
          </div>
          <div>
            <div className="home-t20">Servis Bankacılığı (BaaS), bankaların ve finansal kuruluşların ürün ve hizmetlerini, banka dışı kanallardan kullanılmasına olanak sağlayan yeni nesil Fintech hizmetidir. Bu ürün ve servislere ihtiyaç duyan kişi ve kurumların, banka banka dolaşmadan, ihtiyaç duydukları anda ve ihtiyaç duydukları yerde, tek bir ekrandan yapmalarına imkan sağlar.</div>
            <div className="home-t20 m-t-16">Fingate sayesinde ihtiyaç duyduğunuz tüm banka ürünlerine hızlı, seçenekli ve düşük maliyetle hemen erişebilirsiniz.</div>
          </div>
        </div>
        <div className="d-flex w100 justify-center">
          <img alt="icon" src={picture} className="home-img-3" />
        </div>
        <div className="home-cont1">
          <div className="r">
            <div className="home-r0">
              <img alt="icon" src={ic1} />
              <div className="home-t3 m-t-24">FinGate Platforma Giriş</div>
              <div className="home-t2 m-t-8">FinGate hizmetlerini etkinleştirmek için tek bir API bağlantısına sahip olmak yeterlidir.</div>
            </div>
            <div className="home-r0">
              <img alt="icon" src={ic2} />
              <div className="home-t3 m-t-24">Maliyet Kontrolü</div>
              <div className="home-t2 m-t-8">Ücret taahhütü olmadan sadece gerçekleşen işlem bazlı ödeme yapın.</div>
            </div>
            <div className="home-r0">
              <img alt="icon" src={ic3} />
              <div className="home-t3 m-t-24">Modüler Yapı</div>
              <div className="home-t2 m-t-8">{`${"Modüler yapılı API'ler ile yeni hizmetler oluşturun ve ihtiyaçlarınıza göre güncelleyin."}`}</div>
            </div>
          </div>
          <div className="r0">
            <div className="home-r0">
              <img alt="icon" src={ic4} />
              <div className="home-t3 m-t-24">Satıcıdan Bağımsız</div>
              <div className="home-t2 m-t-8">Mikro hizmet mimarisi, herhangi bir bileşeni veya ürünü değiştirmenize olanak tanır.</div>
            </div>
            <div className="home-r0">
              <img alt="icon" src={ic5} />
              <div className="home-t3 m-t-24">Future Proof Mimarisi</div>
              <div className="home-t2 m-t-8">Gelecekte oluşabilecek değişikliklerden etkilenmeyen mimari yapısıyla temiz bir kod sunulur.</div>
            </div>
            <div className="home-r0">
              <img alt="icon" src={ic6} />
              <div className="home-t3 m-t-24">Sürdürülebilirlik</div>
              <div className="home-t2 m-t-8">Yenilikçi teknolojiye sahip servisler kullanılarak sürekli olarak yenilenir ve güncel kalır.</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
