import React from 'react';
import './sideMenu.css';

function SideMenu({
  children, menuStyle, show, toggle = function () {},
}) {
  return (
    <div className={`sideBar-back ${show && 'show'}`} onClick={() => toggle(!show)}>
      <div className={`${'sideBar'} ${show ? 'show' : ''} ${menuStyle}`}>
        {children}
      </div>
    </div>
  );
}

export default SideMenu;
