import React from 'react';
import Header from '../../views/header/header';
import Footer from '../../views/footer/footer';
import './reference.css';
import bank1 from '../../../assets/images/banks/bank1.svg';
import bank2 from '../../../assets/images/banks/bank2.svg';
import bank3 from '../../../assets/images/banks/bank3.svg';
import bank4 from '../../../assets/images/banks/bank4.svg';
import bank5 from '../../../assets/images/banks/bank5.svg';
import bank6 from '../../../assets/images/banks/bank6.svg';
import bank7 from '../../../assets/images/banks/bank7.svg';
import bank8 from '../../../assets/images/banks/bank8.svg';
import bank9 from '../../../assets/images/banks/bank9.svg';
import partner1 from '../../../assets/images/partners/partner1.svg';
import partner2 from '../../../assets/images/partners/partner2.svg';
import partner3 from '../../../assets/images/partners/partner3.svg';
import partner4 from '../../../assets/images/partners/partner4.svg';
import partner5 from '../../../assets/images/partners/partner5.svg';
import partner6 from '../../../assets/images/partners/kocfinans-logo.svg';
import partner7 from '../../../assets/images/partners/sigola-header-logo.svg';
import partner8 from '../../../assets/images/partners/partner8.svg';

function Reference() {
  return (
    <div className="mainPage">
      <Header />
      <div className="container">
        <div className="reference-cont00">
          <div className="reference-t1">Anlaşmalı Bankalarımız</div>
          <div className="m-t-16 d-none-small">
            <div className="reference-cont1">
              <img alt="bank" src={bank1} />
              <img alt="bank" src={bank2} />
              <img alt="bank" src={bank3} />
              <img alt="bank" src={bank4} />
            </div>
            <div className="reference-cont1">
              <img alt="bank" src={bank5} />
              <img alt="bank" src={bank6} />
              <img alt="bank" src={bank7} />
              <img alt="bank" src={bank8} />
            </div>
            <div className="reference-cont1">
              <img alt="bank" src={bank9} />
            </div>
          </div>
          <div className="d-flex-small f-column m-t-24">
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={bank1} className="reference-img" />
              <img alt="bank" src={bank2} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={bank3} className="reference-img" />
              <img alt="bank" src={bank4} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={bank5} className="reference-img" />
              <img alt="bank" src={bank6} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={bank7} className="reference-img" />
              <img alt="bank" src={bank8} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={bank9} className="reference-img" />
              <div className="reference-img" />
            </div>
          </div>
        </div>
        <div className="reference-cont01">
          <div className="reference-t1">Anlaşmalı İş Ortaklarımız</div>
          <div className="m-t-16 d-none-small">
            <div className="reference-cont1">
              <img alt="bank" src={partner1} />
              <img alt="bank" src={partner2} />
              <img alt="bank" src={partner3} />
              <img alt="bank" src={partner4} />
            </div>
            <div className="reference-cont1">
              <img alt="bank" src={partner5} />
              <img alt="bank" src={partner6} />
              <img alt="bank" src={partner7} />
              <img alt="bank" src={partner8} />
            </div>
          </div>
          <div className="d-flex-small f-column m-t-24">
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={partner1} className="reference-img" />
              <img alt="bank" src={partner2} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={partner3} className="reference-img" />
              <img alt="bank" src={partner4} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={partner5} className="reference-img" />
              <img alt="bank" src={partner6} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={partner7} className="reference-img" />
              <img alt="bank" src={partner8} className="reference-img" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Reference;
